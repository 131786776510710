import React, { useState } from "react";
import axios from "axios";
const baseURL = "http://161.35.28.168:5000/api";

function Empty() {
  

  return (
    <></>
  );
}
export default Empty;
